import { inject } from "@angular/core";
import { AuthStoreService } from "../services/auth-store/auth-store.service";
import {CanActivateFn, Router} from "@angular/router";

export const loggedIn: CanActivateFn = () => {
	const authStore = inject(AuthStoreService);
	const router = inject(Router);

	if (!authStore.user()) {
    return router.parseUrl('/auth');
	}

	return !!authStore.user();
};

export const loggedOut: CanActivateFn = () => {
	const authStore = inject(AuthStoreService);

	const router = inject(Router);
	if (authStore.user()) {
    return router.parseUrl('/users');
	}

	return !authStore.user();
};
